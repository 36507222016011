import Swiper from "swiper/bundle";
import Alpine from "alpinejs";
import { EffectFade } from "swiper/modules";

Alpine.data("HomeHeroBanner", (randomId) => {
  return {
    swiper: null,
    content: null,
    bgMix: null,
    bgBorder: null,

    init() {
      setTimeout(() => {
        this.swiper = new Swiper(".swiper-container" + randomId, {
          loop: true,
          a11y: true,
          autoplay: {
            delay: 6000,
          },
          effect: "fade",
          slidesPerView: 1,
        });
      }, 0);

      this.content = document.querySelector(
        "#home-hero-banner" +
          randomId +
          " .home-hero-banner--content-outer-wrapper"
      );
      this.bgMix = document.querySelector(
        "#home-hero-banner" +
          randomId +
          " .home-hero-banner--background-container--background"
      );
      this.bgBorder = document.querySelector(
        "#home-hero-banner" +
          randomId +
          " .home-hero-banner--background-container--border"
      );
      this.updateHeight();
    },
    updateHeight() {
      requestAnimationFrame(() => {
        const height = this.content.offsetHeight + "px";
        this.bgMix.style.height = height;
        // this.bgBorder.style.height = height;
      });
    },
  };
});
